import React from "react"

export default function TileAncestor(props) {
  const classes = props.classes || []
  return (
    <div className={`tile is-ancestor ${classes.join(" ")}`}>
      {props.children}
    </div>
  )
}
