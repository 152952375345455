import React from "react"
import { portraitPropTypes } from "../../helpers/prop-types"
import Markdown from "../unitary/markdown"
import { generateImage } from "../factories/image-factory"
import { reportHeights } from "../helpers/height-child"
import Content from "../unitary/content"
import { WAIT_FOR_DOM_TO_PAINT_MS } from "../../constants"
import H2 from "../unitary/h2"

export const PORTRAIT_BODY_TEXT_ALIGNMNENTS = Object.freeze({
  left: "left",
})

export const portraitRenderer = ({
  index,
  data,
  images,
  heightRecorder,
  titleMinHeight,
  bodyMinHeight,
  options,
}) => (
  <Portrait
    key={index}
    data={data}
    images={images}
    heightRecorder={heightRecorder}
    titleMinHeight={titleMinHeight}
    bodyMinHeight={bodyMinHeight}
    options={options}
  />
)

export class Portrait extends React.Component {
  constructor(props) {
    super()
    this.reportHeights = reportHeights.bind(this)
  }

  componentDidMount() {
    this.reportHeights()

    setTimeout(() => {
      this.reportHeights()
    }, WAIT_FOR_DOM_TO_PAINT_MS)
  }

  render() {
    let textAlignment = null
    if (
      this.props.options &&
      this.props.options.portraitBodyTextAlignment ===
        PORTRAIT_BODY_TEXT_ALIGNMNENTS.left
    ) {
      textAlignment = "has-text-left"
    }

    let columnModifier = ""
    if (this.props.columnModifier === "is-narrow") {
      columnModifier = "is-narrow"
    }

    const title = this.props.data.title
    const titleMinHeight = this.props.titleMinHeight

    const titleStyles = { paddingTop: "10px", paddingBottom: "10px" }
    if (titleMinHeight) {
      Object.assign(titleStyles, {
        minHeight: titleMinHeight,
      })
    }

    const titleComponent = title ? (
      <div
        ref={titleElement => {
          this.titleElement = titleElement
        }}
        style={titleStyles}
      >
        <H2 title={title} />
      </div>
    ) : null

    const body = this.props.data.body
    const bodyMinHeight = this.props.bodyMinHeight

    const bodyStyles = { paddingBottom: "10px" }

    if (bodyMinHeight) {
      Object.assign(bodyStyles, {
        minHeight: bodyMinHeight,
      })
    }

    const bodyComponent = body ? (
      <div
        ref={bodyElement => {
          this.bodyElement = bodyElement
        }}
        className={`${textAlignment}`}
        style={bodyStyles}
      >
        <Content body={body} />
      </div>
    ) : null

    const imageInnerComponent = generateImage(
      this.props.images,
      this.props.data.image
    )
    const imageComponent = imageInnerComponent ? (
      <div className="py-1 px-2">{imageInnerComponent}</div>
    ) : null

    let caption = null
    if (this.props.data.image && this.props.data.image.caption) {
      caption = this.props.data.image.caption
    }

    const captionComponent = caption ? <Markdown input={caption} /> : null

    return (
      <div className={`column has-text-centered ${columnModifier}`}>
        {titleComponent}
        {bodyComponent}
        {imageComponent}
        {captionComponent}
        {this.props.children}
      </div>
    )
  }
}

Portrait.propTypes = portraitPropTypes
