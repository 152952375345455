import React from "react"

class TileParentChildBox extends React.Component {
  render() {
    return (
      <div className="tile is-parent">
        <div className="tile is-child box">{this.props.children}</div>
      </div>
    )
  }
}

export default TileParentChildBox
