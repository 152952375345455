import React from "react"

class ColumnBox extends React.Component {
  render() {
    return (
      <div className="column">
        <div className="box" style={this.props.style}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default ColumnBox
