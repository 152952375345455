import React from "react"
import Chevron from "./chevron"

const chevronTranslate = "20px"

export const TRANSLATED_CHEVRON_VERTICAL_ALIGNMENTS = Object.freeze({
  top: "top",
})

export default function TranslatedChevron(props) {
  let alignItems = "center"
  if (
    props.options &&
    props.options.translatedChevronVerticalAlignment ===
      TRANSLATED_CHEVRON_VERTICAL_ALIGNMENTS.top
  ) {
    alignItems = "flex-start"
  }
  return (
    <div
      className={`column ${props.chevronColumnWidth}`}
      style={{
        paddingRight: 0,
        marginLeft: `-${chevronTranslate}`,
        display: "flex",
        alignItems: alignItems,
      }}
    >
      <Chevron
        listId={0}
        heightRecorder={props.heightRecorder}
        titleMinHeight={props.titleMinHeight}
        translate={chevronTranslate}
      >
        {props.children}
      </Chevron>
    </div>
  )
}
