import React from "react"
import { portraitRenderer } from "../child-mappable/portrait"
import ChildMapper from "../child-mapper"
import Columns from "../layout/columns"

export function PortraitInColumnsDefaultCentred(props) {
  return (
    <Columns>
      <ChildMapper
        data={props.data.children}
        images={props.images}
        options={props.options}
        render={portraitRenderer}
      />
    </Columns>
  )
}
