// @ts-ignore
import {opgBorderColor, opgBorderRadius, opgBorderWidth} from "../../styles/opg-variables.module.scss"
import React from "react"
import ResponsiveContext from "../../contexts/responsive-context"
import { reportHeights } from "../helpers/height-child"
import { WAIT_FOR_DOM_TO_PAINT_MS } from "../../constants"
import { setStateToClient } from "../helpers/is-client-class"

const CHEVRON_WIDTH = "3%"
const CHEVRON_PADDING = "10px"

const borderAllProperties = `${opgBorderWidth} solid ${opgBorderColor}`

const linearGradient = toCorner => {
  const fromColor = "#fff"
  const toColor = "rgba(0,0,0,0)"
  const lineColor = opgBorderColor
  return `linear-gradient(${toCorner}, ${fromColor} calc(50% - ${opgBorderWidth}), ${lineColor}, ${toColor} calc(50% + ${opgBorderWidth}) )`
}

class Chevron extends React.Component {
  constructor(props) {
    super()
    this.reportHeights = reportHeights.bind(this)
    this.setStateToClient = setStateToClient.bind(this)
    this.state = { serverOrClient: "server" }
  }

  componentDidMount() {
    this.setStateToClient()
    this.reportHeights()

    setTimeout(() => {
      this.reportHeights()
    }, WAIT_FOR_DOM_TO_PAINT_MS)
  }

  render() {
    const innerDivMinHeight = this.props.titleMinHeight

    const hasLeftIndent = this.props.listId === 0 ? false : true
    const isLastChild = this.props.isLastChild

    const borderStyles = ["solid", "solid", "solid", "solid"]
    if (!isLastChild) {
      borderStyles[1] = "none"
    }
    if (hasLeftIndent) {
      borderStyles[3] = "none"
    }

    const innerDivStyles = {
      background: "white",
      borderWidth: opgBorderWidth,
      borderColor: opgBorderColor,
      marginBottom: "-1px",
      padding: "0.5em 0.75em",
      borderStyle: borderStyles.join(" "),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }

    if (!hasLeftIndent) {
      Object.assign(innerDivStyles, {
        borderTopLeftRadius: opgBorderRadius,
        borderBottomLeftRadius: opgBorderRadius,
      })
    }

    if (isLastChild) {
      Object.assign(innerDivStyles, {
        borderTopRightRadius: opgBorderRadius,
        borderBottomRightRadius: opgBorderRadius,
      })
    }

    if (innerDivMinHeight) {
      Object.assign(innerDivStyles, {
        minHeight: innerDivMinHeight,
      })
    }

    let topLeftTd = null

    if (hasLeftIndent) {
      topLeftTd = (
        <td
          style={{
            width: CHEVRON_WIDTH,
            background: linearGradient("to bottom left"),
            borderBottom: "none",
            borderTop: borderAllProperties,
            padding: CHEVRON_PADDING,
          }}
        ></td>
      )
    }

    let bottomLeftTd = null

    if (hasLeftIndent) {
      bottomLeftTd = (
        <td
          style={{
            background: linearGradient("to top left"),
            borderBottom: borderAllProperties,
            padding: CHEVRON_PADDING,
          }}
        ></td>
      )
    }

    let topRightTd = (
      <td
        style={{
          width: CHEVRON_WIDTH,
          background: linearGradient("to top right"),
          border: "none",
          padding: CHEVRON_PADDING,
        }}
      ></td>
    )

    let bottomRightTd = (
      <td
        style={{
          background: linearGradient("to bottom right"),
          padding: CHEVRON_PADDING,
        }}
      ></td>
    )

    if (isLastChild) {
      topRightTd = null
      bottomRightTd = null
    }

    const tableStyles = {
      background: "none",
      width: "100%",
    }

    const translate = this.props.translate
    if (translate) {
      Object.assign(tableStyles, { transform: `translate(${translate})` })
    }

    const desktopComponent = (
      <table
        className="table"
        ref={titleElement => {
          this.titleElement = titleElement
        }}
        style={tableStyles}
      >
        <tbody>
          <tr>
            {topLeftTd}
            <td rowSpan={2} style={{ padding: 0 }}>
              <div style={innerDivStyles}>{this.props.children}</div>
            </td>
            {topRightTd}
          </tr>
          <tr>
            {bottomLeftTd}
            {bottomRightTd}
          </tr>
        </tbody>
      </table>
    )

    const component = this.context.isMobile
      ? this.props.children
      : desktopComponent

    return (
      <div key={this.state.serverOrClient} style={{ flex: 1 }}>
        {component}
      </div>
    )
  }
}

Chevron.contextType = ResponsiveContext

export default Chevron
