export function reportHeights() {
  if (this.props.heightRecorder) {
    if (this.titleElement) {
      this.props.heightRecorder("title", this.titleElement.clientHeight)
    }
    if (this.bodyElement) {
      this.props.heightRecorder("body", this.bodyElement.clientHeight)
    }
  }
}
