export function setInitialHeights(elementNames) {
  const state = {}
  elementNames.forEach(element => {
    Object.assign(state, {
      [`${element}Height`]: 0,
    })
  })

  this.state = state
}

export function heightRecorder(element, height) {
  if (!this.variableHeightElements.includes(element)) {
    throw new Error(`Unknown element: ${element}`)
  }

  const heightKey = `${element}Height`
  if (height > this.state[heightKey]) {
    this.setState(prevState => ({
      [heightKey]: Math.max(prevState[heightKey], height),
    }))
  }
}
