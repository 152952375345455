import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const VALID_BORDER_TYPES = ["none", "shadow", "line"]

export function generateImage(images, image) {
  let component = null

  if (image) {
    let borderType = image.border_type
    if (!borderType) {
      throw new Error(`No border type set for image ${image.name}`)
    }

    if (!VALID_BORDER_TYPES.includes(borderType)) {
      throw new Error(
        `Image ${image.name} has invalid border type ${borderType}`
      )
    }

    const imageFile = images[image.name].childImageSharp.gatsbyImageData

    let classToCenterImage = ""
    if (imageFile.layout === "fixed") {
      classToCenterImage = "is-inline-block"
    }

    component = (
      <GatsbyImage
        image={images[image.name].childImageSharp.gatsbyImageData}
        alt={image.alt}
        className={`opg-border-type-${borderType} ${classToCenterImage}`}
      />
    )
  }

  return component
}
