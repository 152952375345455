import React from "react"
import { H2_STYLE_CLASSES } from "../../constants"
import Markdown from "./markdown"

class H1Small extends React.Component {
  render() {
    const markdown = <Markdown input={this.props.title} />

    return <h1 className={H2_STYLE_CLASSES.join(" ")}>{markdown}</h1>
  }
}

export default H1Small
