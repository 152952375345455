import React from "react"
import ResponsiveContext from "../../contexts/responsive-context"
import TitleWithChildAndUnderline from "../unitary/title-with-child-and-underline"
import { Section } from "./section"
import { SectionBox } from "./section-box"
import Columns from "../layout/columns"
import ColumnBox from "../layout/column-box"
import { setStateToClient } from "../helpers/is-client-class"
import TranslatedChevron from "../unitary/translated-chevron"

export const TOOTHPASTE_OPTIONS = Object.freeze({
  toothpasteHasBox: { true: true, false: false },
})

const DEFAULT_OPTIONS = {
  toothpasteHasBox: true,
}

class Toothpaste extends React.Component {
  constructor(props) {
    super()
    this.setStateToClient = setStateToClient.bind(this)
    this.state = { serverOrClient: "server" }
  }

  componentDidMount() {
    this.setStateToClient()
  }

  render() {
    const options = Object.assign(DEFAULT_OPTIONS, this.props.options)

    let boxStyle = {}

    if (!options.toothpasteHasBox) {
      boxStyle = { boxShadow: "none" }
    }

    const desktopComponent = (
      <Section>
        <Columns>
          <TranslatedChevron
            titleMinHeight={this.props.titleMinHeight}
            chevronColumnWidth={this.props.chevronColumnWidth}
            heightRecorder={this.props.heightRecorder}
            options={this.props.options}
          >
            {this.props.titleChild}
          </TranslatedChevron>
          <ColumnBox style={boxStyle}>{this.props.children}</ColumnBox>
        </Columns>
      </Section>
    )

    const mobileComponent = (
      <SectionBox style={boxStyle}>
        <TitleWithChildAndUnderline>
          {this.props.titleChild}
        </TitleWithChildAndUnderline>

        <div className="columns">
          <div className="column">{this.props.children}</div>
        </div>
      </SectionBox>
    )

    const component = this.context.isMobile ? mobileComponent : desktopComponent
    return <div key={this.state.serverOrClient}>{component}</div>
  }
}

Toothpaste.contextType = ResponsiveContext

export default Toothpaste
