import React from "react"
import { heightRecorder, setInitialHeights } from "./helpers/height-parent"
import ResponsiveContext from "../contexts/responsive-context"

export const CHILD_MAPPER_ALIGN_ELEMENTS = Object.freeze({
  false: "false",
})

class ChildMapper extends React.Component {
  constructor(props) {
    super()
    this.variableHeightElements = ["title", "body"]
    this.setInitialHeights = setInitialHeights.bind(this)
    this.setInitialHeights(this.variableHeightElements)
    this.heightRecorder = heightRecorder.bind(this)
  }

  render() {
    const dontAlign =
      this.props.options &&
      this.props.options.childMapperAlignElements ===
        CHILD_MAPPER_ALIGN_ELEMENTS.false

    return this.props.data.map((child, index) => {
      const isMobile = this.context.isMobile
      return this.props.render({
        index: index,
        data: child,
        images: this.props.images,
        heightRecorder: this.heightRecorder,
        titleMinHeight: isMobile || dontAlign ? null : this.state.titleHeight,
        bodyMinHeight: isMobile || dontAlign ? null : this.state.bodyHeight,
        isLastChild: index + 1 === this.props.data.length,
        options: this.props.options,
      })
    })
  }
}

ChildMapper.contextType = ResponsiveContext

export default ChildMapper
