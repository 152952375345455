import React from "react"
import { PortraitInColumnsDefaultCentred } from "../portrait-children/portraits-in-columns-default-centred"
import TitleWithUnderline from "../unitary/title-with-underline"
import { SectionBox } from "./section-box"

export function PortraitArrayWithTitle(props) {
  return (
    <SectionBox>
      <TitleWithUnderline title={props.data.title} />
      <PortraitInColumnsDefaultCentred {...props} />
    </SectionBox>
  )
}
