import React from "react"
import TileAncestor from "../layout/tile-ancestor"
import { Section } from "./section"

export function SectionTileAncestor(props) {
  return (
    <Section>
      <TileAncestor>{props.children}</TileAncestor>
    </Section>
  )
}
