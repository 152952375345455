import React, { useContext } from "react"
import H1Small from "./h1-small"
import ResponsiveContext from "../../contexts/responsive-context"
import useIsClient from "../is-client-hook"
import H1 from "./h1"

export default function ResponsiveTitlePlain(props) {
  const context = useContext(ResponsiveContext)
  const isClient = useIsClient().isClient

  if (!isClient) {
    return null
  }

  if (context.isMobile) {
    return <H1 title={props.title} />
  } else {
    return (
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <H1Small title={props.title} />
      </div>
    )
  }
}
