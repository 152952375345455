import React from "react"

export default function TitleWithChildAndUnderline(props) {
  return (
    <div
      className="columns"
      style={{
        marginBottom: "-0.75em",
      }}
    >
      <div className="column has-text-centered pb-0">
        {props.children}
        <hr />
      </div>
    </div>
  )
}
