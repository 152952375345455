import React from "react"
import H1 from "./h1"

export default function TitleWithUnderline(props) {
  return (
    <div
      className="columns"
      style={{
        marginBottom: "-0.75em",
      }}
    >
      <div className="column has-text-centered pb-0">
        <H1 title={props.title} />
        <hr />
      </div>
    </div>
  )
}
