// @ts-ignore
import yaml from "../../data/ecommerce-strategy-for-brands.yaml"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import Header from "../../components/sections/header"
import { graphql } from "gatsby"
import LayoutContainer from "../../components/layout/layout-container"
import { PORTRAIT_BODY_TEXT_ALIGNMNENTS } from "../../components/child-mappable/portrait"
import { PortraitArrayWithTitle } from "../../components/sections/portrait-array-with-title"
import ResponsiveTitlePlain from "../../components/unitary/responsive-title-plain"
import Toothpaste from "../../components/sections/toothpaste"
import Content from "../../components/unitary/content"
import TileParentChildBox from "../../components/layout/tile-parent-child-box"
import TitleWithUnderline from "../../components/unitary/title-with-underline"
import { SectionTileAncestor } from "../../components/sections/section-tile-ancestor"
import { CHILD_MAPPER_ALIGN_ELEMENTS } from "../../components/child-mapper"

class eCommerce extends React.Component {
  render() {
    validatePageYamlData(yaml)

    const images = this.props.data

    const introductionToothpasteTitleChild = (
      <ResponsiveTitlePlain title={yaml.introduction.title} />
    )

    return (
      <LayoutContainer>
        <Header {...yaml.header} />
        <Toothpaste
          titleChild={introductionToothpasteTitleChild}
          chevronColumnWidth={"is-3"}
        >
          <Content body={yaml.introduction.markdown} />
        </Toothpaste>
        <SectionTileAncestor>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.healthy_funnel.title} />
            <Content body={yaml.healthy_funnel.body} />
          </TileParentChildBox>
          <TileParentChildBox>
            <TitleWithUnderline title={yaml.specialist_retailer.title} />
            <Content body={yaml.specialist_retailer.body} />
          </TileParentChildBox>
        </SectionTileAncestor>
        <PortraitArrayWithTitle
          data={yaml.own_retail_site}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <PortraitArrayWithTitle
          data={yaml.succeed_on_amazon}
          images={images}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
            childMapperAlignElements: CHILD_MAPPER_ALIGN_ELEMENTS.false,
          }}
        />
        <PortraitArrayWithTitle
          data={yaml.innovative_and_agile}
          images={images}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
            childMapperAlignElements: CHILD_MAPPER_ALIGN_ELEMENTS.false,
          }}
        />
      </LayoutContainer>
    )
  }
}

export default eCommerce

export const query = graphql`
  query {
    colourpop_fruit_roots_is_out_of_stock: file(
      relativePath: {
        eq: "ecommerce/colourpop_fruit_roots_is_out_of_stock.png"
      }
    ) {
      ...fixedWidth340
    }
    data_propagation_diagram: file(
      relativePath: { eq: "ecommerce/data_propagation_diagram.png" }
    ) {
      ...fixedWidth400
    }
    elon_musk_new_feature_sure: file(
      relativePath: { eq: "ecommerce/elon_musk_new_feature_sure.png" }
    ) {
      ...fixedWidth400
    }
  }
`
